import { React, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    Box, Drawer, Toolbar, List,
    Typography,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemButton,
    ListItemText,
    Collapse,
    AppBar as MuiAppBar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import {PersonOutline  } from '@mui/icons-material';
import TableViewIcon from '@mui/icons-material/TableView';
import SourceIcon from '@mui/icons-material/Source';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import CallIcon from '@mui/icons-material/Call';

const drawerWidth = 240;


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


export default function Navbar(props) {
    const { open, setOpen, admin } = props;
    const theme = useTheme();
    const location = useLocation();
    const [isMasters, setIsMasters] = useState(false);
    const [isContent, setIsContent] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [isSetting, setIsSetting] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setIsMasters(!isMasters);
    };

    const handleClickContent = () => {
        setIsContent(!isContent);
    };
    const handleClickUser = () => {
        setIsUser(!isUser);
    };
    const handleClickSetting = () => {
        setIsSetting(!isSetting);
    };

    const isActive = (path) => {
        return location.pathname === path;
    };

    return (
        <Box >

            <AppBar
                open={open}
                position='relative'

                sx={{
                    marginTop: '57px',
                    bgcolor: 'white',
                    color: 'black',
                    boxShadow: 1
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerClose}
                        edge="start"
                        sx={{ mr: 2, ...(!open && { display: 'none' }) }}
                    >
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"

                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon onClick={handleDrawerOpen} />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Property Owner Dashboard
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    scrollbarWidth: 'none',
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        scrollbarWidth: 'none',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <Divider />
                    <List
                        sx={{
                            marginTop: '57px',
                        }}
                    >
                        {/* <Link to={'/dashboard'} style={{ textDecoration: 'none' }}>
                                <ListItemButton sx={{
                                    bgcolor: isActive('/dashboard') ? '#FD226E' : '',
                                    pl: 2
                                }}>
                                    <ListItemIcon>
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={
                                            <Typography sx={{
                                                color: isActive('/dashboard') ? '#FFF' : '',
                                                '&:hover': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                },
                                            }}>Dashboard</Typography>
                                        }
                                    />
                                </ListItemButton>
                            </Link> */}
                            <Link to={'/property'} style={{ textDecoration: 'none' }}>
                                <ListItemButton sx={{
                                    bgcolor: isActive('/property') ? '#FD226E' : '',
                                    pl: 2
                                }}>
                                    <ListItemIcon>
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={
                                            <Typography sx={{
                                                color: isActive('/property') ? '#FFF' : '',
                                                '&:hover': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                },
                                            }}>Properties</Typography>
                                        }
                                    />
                                </ListItemButton>
                            </Link>
                            {/* <Link to={'/booking-table'} style={{ textDecoration: 'none' }}>
                                <ListItemButton sx={{
                                    bgcolor: isActive('/booking-table') ? '#FD226E' : '',
                                    pl: 2
                                }}>
                                    <ListItemIcon>
                                        <PersonOutline />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography sx={{
                                                color: isActive('/booking-table') ? '#FFF' : '',
                                                '&:hover': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                },
                                            }}>Bookings</Typography>
                                        }
                                    />
                                </ListItemButton>
                            </Link> */}
                            <Link to={'/booking-calendar'} style={{ textDecoration: 'none' }}>
                                <ListItemButton sx={{
                                    bgcolor: isActive('/booking-calendar') ? '#FD226E' : '',
                                    pl: 2
                                }}>
                                    <ListItemIcon>
                                        <CalendarMonthOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography sx={{
                                                color: isActive('/booking-calendar') ? '#FFF' : '',
                                                '&:hover': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                },
                                            }}>Booking Calendar</Typography>
                                        }
                                    />
                                </ListItemButton>
                            </Link>
                        <ListItemButton onClick={handleClickSetting}>
                            <ListItemIcon>
                                <SettingsOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Setting" />
                            {isSetting ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={isSetting} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Link to={'/profile-setting'} style={{ textDecoration: 'none' }}>
                                    <ListItemButton sx={{
                                        bgcolor: isActive('/profile-setting') ? '#FD226E' : '',
                                        pl: 2
                                    }}>
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography sx={{
                                                    color: isActive('/profile-setting') ? '#FFF' : '',
                                                    '&:hover': {
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                    },
                                                }}>Profile</Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Link>
                                {/* <Link to={'/razorpay-setting'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/razorpay-setting') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/razorpay-setting') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Razorpay Auth</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link> */}
                            </List>
                        </Collapse>
                    </List>
            </Drawer>

        </Box>
    );
}
import React, { lazy, Suspense } from "react";
import { ProtectedRoute } from './app/protected.route';
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";

const LoginContainer = lazy(() => import('./view/login/login.container'));
const DashboardContainer = lazy(() => import('./view/dashboard/dashboard.container'));
const ForgotPasswordContainer = lazy(() => import('./view/forgot-password/forgot-password.container'));
const ResetPasswordContainer = lazy(() => import('./view/reset-password/reset-password.container'));
// const BookingPropertyTableContainer = lazy(()=> import('./view/booking-table/bookingTable.container'));
const BookingCalendarContainer = lazy(()=> import('./view/booking-calendar/booking-calendar.container'));
const BookingCalendarMultiContainer = lazy(()=> import('./view/booking-calendar-multi/booking-calendar-multi.container'));
const PropertyContainer = lazy(()=> import('./view/property/property.container'));
const ProfileSettingContainer = lazy(()=> import('./view/setting/profile-setting/profile-setting.container'));
const UpdatePasswordContainer = lazy(()=> import('./view/setting/password-setting/password-setting.container'));

const RoutesPath = () => {
    return (
        <Box>
            <Routes>
                <Route path="/login" element={<Suspense fallback={<>...</>}><LoginContainer /></Suspense>} />
                <Route path="/" element={<Suspense fallback={<>...</>}><ProtectedRoute><PropertyContainer /></ProtectedRoute></Suspense>} />
                <Route path="/forgot-password" element={<Suspense fallback={<>...</>}><ForgotPasswordContainer /></Suspense>} />
                <Route path="/reset-password/:token" element={<Suspense fallback={<>...</>}><ResetPasswordContainer /></Suspense>} />
                <Route path="/property" element={<Suspense fallback={<>...</>}><ProtectedRoute><PropertyContainer /></ProtectedRoute></Suspense>} />
                {/* <Route path="/booking-table" element={<Suspense fallback={<>...</>}><ProtectedRoute><BookingPropertyTableContainer /></ProtectedRoute></Suspense>} /> */}
                <Route path="/booking-calendar" element={<Suspense fallback={<>...</>}><ProtectedRoute><BookingCalendarContainer /></ProtectedRoute></Suspense>} />
                <Route path="/booking-calendar-multi" element={<Suspense fallback={<>...</>}><ProtectedRoute><BookingCalendarMultiContainer /></ProtectedRoute></Suspense>} />
                <Route path="/profile-setting" element={<Suspense fallback={<>...</>}><ProtectedRoute><ProfileSettingContainer /></ProtectedRoute></Suspense>} />
                <Route path="/update-password" element={<Suspense fallback={<>...</>}><ProtectedRoute><UpdatePasswordContainer /></ProtectedRoute></Suspense>} />
            </Routes>
        </Box>
    )
}

export default RoutesPath;
import { combineReducers } from "redux";
import { adminReducer as adminPage } from "./../api/admin/adminReducer";
import { propertyReducer  as propertyPage } from "../api/property/propertyReducer";
import { bookingReducer as bookingPage } from "../api/booking/bookingReducer";
import { razorpaySettingReducer as razorpaySettingPage } from "../api/razorpay-setting/razorpaySettingReducer";

const appReducer = combineReducers({
    adminPage,
    propertyPage,
    bookingPage,
    razorpaySettingPage
})

export default appReducer;